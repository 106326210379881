export default function inAppBrowser(uri) {
  if (uri.substring(0, 4) === "http") {
    window.open(uri, "_blank");
    return false;
  }
  if (uri.substring(0, 3) === "tel") {
    window.open(uri, "_system");
    return false;
  }
  if (uri.substring(0, 5) === "mailto") {
    window.open(uri, "_system");
    return false;
  }
  return true;
}
