import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import styles from "./onboarding.module.scss";
import Logo from "../../img/logo_full.svg";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Arrow from "../../img/carousel-arrow-green.svg";
import Switch from "react-switch";
import UserService, { NotificationItem } from "../../services/user.service";
import NewsIcon from "../../img/profile/notifications/news.svg";
import DocumentsIcon from "../../img/profile/notifications/documents.svg";
import EventsIcon from "../../img/profile/notifications/events.svg";
import InvestmentsIcon from "../../img/profile/notifications/investments.svg";
import UppiesIcon from "../../img/profile/notifications/uppies.svg";

const StyledSlider = styled(Slider)`
  .slick-next,
  .slick-prev {
    display: none !important;
  }

  .slick-list {
    height: 100%;
    overflow-y: auto;
  }

  .slick-track {
    display: flex !important;
  }

  @media (max-width: 768px) {
    .slick-track {
      height: 100%;
    }
  }

  .slick-slide {
    height: inherit !important;
  }

  .slick-slide > div {
    height: 100%;
  }

  .slick-disabled {
    display: none !important;
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    margin-top: 15px;
  }

  .slick-dots li {
    width: 50px;
  }

  .slick-dots li button {
    height: 4px;
    background-color: #c6d0d0;
    border-radius: 16px;
    flex: 1;
    width: 100%;
    padding: 0;
  }

  .slick-dots li.slick-active button {
    background-color: #d1f178;
  }

  .slick-dots li button:before {
    display: none;
  }

  @media (min-width: 768px) {
    .slick-dots li button {
      height: 8px;
    }
  }
`;

export default function Onboarding() {
  const { t } = useTranslation("home");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<NotificationItem[]>();
  const [sliderRef, setSliderRef] = useState<any>(null);

  const settingIcons: Record<string, string> = {
    News: NewsIcon,
    Documents: DocumentsIcon,
    Events: EventsIcon,
    Uppies: UppiesIcon,
    Projects: InvestmentsIcon,
  };

  useEffect(() => {
    UserService.getUserNotificationSettings().then((response) => {
      setNotifications(response);
    });
  }, []);

  const settings = {
    arrows: true,
    infinite: false,
    dots: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: false,
    draggable: false,
    swipe: false,
  };

  return (
    <>
      <div className={styles.onboarding_wrapper}>
        <StyledSlider ref={setSliderRef} {...settings}>
          <div className={styles.onboarding_step_one}>
            <div className={styles.image}>
              <img src={Logo} alt="Logo" width={170} height={40}></img>
            </div>
            <div className={styles.title}>{t("onboarding_title")}</div>
            <div className={styles.body}>{t("onboarding_text")}</div>
            <div className={styles.custom_next}>
              <button onClick={sliderRef?.slickNext}>
                {t("onboarding_next")}
              </button>
              <img src={Arrow} />
            </div>
          </div>
          <div className={styles.onboarding_step_two}>
            <div className={styles.image}>
              <img src={Logo} alt="logo" width={170} height={40}></img>
            </div>
            <div className={styles.title}>{t("notifications_title")}</div>
            <div className={styles.body}>{t("notifications_body")}</div>
            {notifications ? (
              <div className={styles.profile}>
                <div className={`${styles.settings} ${styles.colouredBox} `}>
                  {notifications.map((notification, i) => {
                    return (
                      <div
                        className={`${styles.item} ${styles.itemWithBottomLine}`}
                        key={i}
                      >
                        <div className={styles.itemIcon}>
                          <img
                            width={26}
                            height={26}
                            src={settingIcons[notification.name_original]}
                            alt={"Notification settings icon"}
                          />
                        </div>
                        <div>
                          <div className={styles.itemText}>
                            {notification.name}
                          </div>
                        </div>
                        <div className={styles.itemCheckbox}>
                          <Switch
                            checked={notification.active}
                            uncheckedIcon={false}
                            offColor="#EBEBEB"
                            onColor="#7FD325"
                            width={44}
                            height={24}
                            handleDiameter={20}
                            checkedIcon={false}
                            disabled={isLoading}
                            onChange={async (state) => {
                              setLoading(true);
                              await UserService.updateUserNotificationSettings({
                                id: notification.id,
                                name: notification.name,
                                name_original: "",
                                active: state.valueOf(),
                              });
                              await UserService.getUserNotificationSettings().then(
                                (response) => {
                                  setNotifications(response);
                                }
                              );
                              setLoading(false);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className={styles.action_wrapper}>
              <span
                className={styles.primary_button}
                onClick={async (e) => {
                  await UserService.updateUserOnboardingSettings(true);
                  window.location.reload();
                }}
              >
                {t("onboarding_submit")}
              </span>
            </div>
          </div>
        </StyledSlider>
      </div>
    </>
  );
}
